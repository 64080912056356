import {ApiUrl, instance} from '../apiEnv';

export const api_statuses_history = {

    async getStatuses() {
        let result = await instance.get(`${ApiUrl}/detail-histories`);
        return result.data;
    },
    async addStatus(data) {
        let result = await instance.post(`${ApiUrl}/detail-histories`, data, {
            headers: {
                'content-type': 'multipart/form-data',
            }
        });
        return result.data;
    },
    async getStatus(id) {
        let result = await instance.get(`${ApiUrl}/details/${id}`);
        return result.data?.data?.histories;
    },

}