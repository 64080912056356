import React from 'react';
import uploadColored from 'assets/images/icons/uploadColored.svg'
import editColored from 'assets/images/icons/editColored.svg'
import deleteColored from 'assets/images/icons/deleteColored.svg'
import download from 'assets/images/icons/download.svg'
import './style.css';

const FileCard = ({onlyDelete, file, removeFile, onlyDownload, onlyUpload, onlyEdit}) => {

    const content = () => {
        return (
            <>
                <p className='p15_600'>{onlyDownload ? "Download file" : file.name}</p>
                <div className='fileCard_actionButtonsContainer'>
                    {onlyUpload && <img className='fileCard_img' src={uploadColored} alt="uploadColored"/>}
                    {onlyEdit && <img className='fileCard_img' src={editColored} alt="editColored"/>}
                    {onlyDownload && <img className='fileCard_img' src={download} alt="editColored"/>}
                    {onlyDelete && <img className='fileCard_img' src={deleteColored} alt="deleteColored"
                                        onClick={() => removeFile(null)}/>}
                </div>
            </>
        )
    }

    if(onlyDownload){
        return (
            <a className='fileCard_container link' href={file}>
                {content()}
            </a>
        )
    }else{
        return (
            <div className='fileCard_container'>
                {content()}
            </div>
        )
    }
}

export default FileCard