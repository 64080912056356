export const menuList = [
    {
        id: 1 ,
        title: 'Models',
        img: <svg width="58" height="64" viewBox="0 0 58 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M55.6693 15.3333L29.0026 2L2.33594 15.3333M55.6693 15.3333L29.0026 28.6667M55.6693 15.3333V48.6667L29.0026 62M2.33594 15.3333L29.0026 28.6667M2.33594 15.3333V48.6667L29.0026 62M29.0026 28.6667V62" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>,
        path: '/admin/my-models'
    },
    {
        id: 2,
        title: 'Status',
        img: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.44 6.95984L13.7867 9.74651C13.2267 12.1532 12.12 13.1265 10.04 12.9265C9.70667 12.8998 9.34667 12.8398 8.96 12.7465L7.84 12.4798C5.06 11.8198 4.2 10.4465 4.85333 7.65984L5.50667 4.86651C5.64 4.29984 5.8 3.80651 6 3.39984C6.78 1.78651 8.10667 1.35318 10.3333 1.87984L11.4467 2.13984C14.24 2.79318 15.0933 4.17318 14.44 6.95984Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.04 12.9266C9.62667 13.2066 9.10667 13.4399 8.47333 13.6466L7.42 13.9932C4.77333 14.8466 3.38 14.1332 2.52 11.4866L1.66667 8.85324C0.813332 6.20657 1.52 4.80657 4.16667 3.95324L5.22 3.60657C5.49333 3.5199 5.75333 3.44657 6 3.3999C5.8 3.80657 5.64 4.2999 5.50667 4.86657L4.85333 7.6599C4.2 10.4466 5.06 11.8199 7.84 12.4799L8.96 12.7466C9.34667 12.8399 9.70667 12.8999 10.04 12.9266Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.42667 5.68652L11.66 6.50652" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.77333 8.2666L9.70667 8.75994" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>,
        path: '/admin/statuses'
    },
]