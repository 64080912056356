export const adminStatusTable = [
    {
        title: 'Name',
        sort: false
    },
    {
        title: 'Sort',
        sort: false
    },
    {
        title: 'Color',
        sort: false
    },
    {
        title: '',
        sort: false
    },
    {
        title: '',
        sort: false
    },
]

export const adminModelTable = [
    {
        title: 'Name',
        sort: true,
        sort_param: 'name',
    },
    {
        title: 'Zone',
        sort: true,
        sort_param: 'zone',
    },
    {
        title: 'Group',
        sort: true,
        sort_param: 'group',
    },
    {
        title: 'Status',
        sort: true,
        sort_param: 'detail_status_id',
    },
    {
        title: 'Date',
        sort: true,
        sort_param: 'created_at',
    },
    {
        title: 'ID',
        sort: true,
        sort_param: 'id',
    },
    {
        title: 'NFC tag',
        sort: true,
        sort_param: 'nfc',
    },

]