export const SortList = (array = [], order) => {
  let result = [];

  console.log(array);

  if (order === "asc") {
    result = array.sort((a, b) => (a.sort > b.sort ? 1 : -1));
  } else if (order === "desc") {
    result = array.sort((a, b) => (a.sort < b.sort ? 1 : -1));

    array.sort((a, b) => console.log(a, b));
  } else {
    return array;
  }

  return result;
};
