import {ApiUrl, instance} from '../apiEnv';

export const api_statuses = {

    async getStatuses(query) {
        let queryString = Object.keys(query).reduce((prev, key) => {
            return `${prev ? prev + '&' : ''}${key}=${query[key]}`
        }, '')
        let result = await instance.get(`${ApiUrl}/detail-statuses?${queryString}`);
        return result.data;
    },
    async addStatuses(data) {
        let result = await instance.post(`${ApiUrl}/detail-statuses`, data);
        return result.data;
    },
    async updateStatuses(data, id) {
        let result = await instance.put(`${ApiUrl}/detail-statuses/${id}`, data);
        return result.data;
    },
    async deleteStatuses(id) {
        let result = await instance.delete(`${ApiUrl}/detail-statuses/${+id}`);
        return result.data;
    }

}