import { ApiUrl, instance } from "../apiEnv";

export const api_details = {
  async searchDetails({
    page = 1,
    group,
    zone,
    status,
    search,
    limit = 50,
    sort,
    order_type,
  }) {
    let result = await instance.get(
      `${ApiUrl}/details?group=${group}&zone=${zone}&status=${status}&limit=${limit}&page=${page}&search=${search}&order=${sort}&order_type=${order_type}`
    );

    return result.data;
  },

  async getDetails(query) {
    let queryString = Object.keys(query).reduce((prev, key) => {
      return `${prev ? prev + "&" : ""}${key}=${query[key]}`;
    }, "");
    let result = await instance.get(`${ApiUrl}/details?${queryString}`);
    return result.data;
  },
  async addDetails(data) {
    let result = await instance.post(`${ApiUrl}/details`, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    return result.data;
  },

  async getDetailByID(id) {
    let result = await instance.get(`${ApiUrl}/details/${id}`);
    return result.data;
  },
  async patchDetailByID(data, id) {
    let result = await instance.post(`${ApiUrl}/details/${id}`, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    return result.data;
  },
  async deleteDetailByID(id) {
    let result = await instance.delete(`${ApiUrl}/details/${+id}`);
    return result.data;
  },
};
