import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import CustomModal from "components/shared/customModal/CustomModal";
import "antd/dist/reset.css";
import "assets/styles/globalStyles.css";
import { routingList } from "configs/routings/routings";
import styles from "./components/components/modalChildrens/addNewModel/styles.module.scss";
import { useForm } from "react-hook-form";
import SimpleButton from "./components/shared/buttons/simpleButton/SimpleButton";
import { api_technics } from "./api/admin/api";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

function App() {
  const [routing, setRouting] = useState([]);
  const [activeRoutingWindow, setActiveRoutingWindow] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  useEffect(() => {
    if (window.location.pathname.indexOf("user") > -1) {
      handleUserLayout();
    }
    let token = localStorage.getItem("studia-3d-cloud-token");
    if (token) {
      handleAdminLayout();
    }
  }, []);
  const onSubmit = async (data) => {
    let result = await api_technics.login(data);
    if (result) {
      window.location.reload();
    }
  };

  const handleAdminLayout = () => {
    setRouting(routingList.admin);
    setActiveRoutingWindow(false);
  };
  const handleUserLayout = () => {
    setRouting(routingList.user);
    setActiveRoutingWindow(false);
  };

  return (
    <>
      <ReactNotifications />
      {activeRoutingWindow && (
        <div
          style={{
            width: `100%`,
            height: `100vh`,
            display: `flex`,
            flexDirection: "column",
            justifyContent: `center`,
            alignItems: `center`,
            gap: `1rem`,
          }}
        >
          <h1>Авторизация</h1>
          <input
            {...register("email")}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            className={`${styles.input} ${errors.email ? styles.error : ""}`}
            placeholder="Email"
            type="email"
          />
          <input
            {...register("password")}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            className={`${styles.input} ${errors.password ? styles.error : ""}`}
            placeholder="Password"
            type="password"
          />
          <div style={{ alignSelf: "center", width: "200px" }}>
            <SimpleButton title={"Log in"} onClick={handleSubmit(onSubmit)} />
          </div>
        </div>
      )}
      <Routes>
        {routing.map((item, i) => {
          if (item.children) {
            return (
              <>
                <Route key={i} path={item.path} element={item.element}>
                  {item.children.map((item, key) => {
                    if (item.index) {
                      return (
                        <Route
                          index
                          element={item.element}
                          key={`route_${key}`}
                        />
                      );
                    } else {
                      return (
                        <Route
                          path={item.path}
                          element={item.element}
                          key={`route_${key}`}
                        />
                      );
                    }
                  })}
                </Route>
                <Route
                  path="*"
                  element={<Navigate to={item.navigate} replace />}
                />
              </>
            );
          }
        })}
      </Routes>
      <CustomModal />
    </>
  );
}

export default App;
