import {ApiUrl, instance} from '../apiEnv';

export const api_technics = {

    async login(data) {
        let result = await instance.post(`${ApiUrl}/login`, data);
        localStorage.setItem('studia-3d-cloud-token', result.data.data.token)
        return result;
    },

    async logout() {
        let result = await instance.post(`${ApiUrl}/logout`);
        localStorage.removeItem('studia-3d-cloud-token');
    },

}