import React, { useEffect, useState } from "react";
import "./dropdown.scss";
import "../tableCard/style.module.scss";

const DropDown = ({
  children,
  btnText,
  contentWidth,
  id,
  currentId,
  className,
}) => {
  const [hover, setHover] = useState(false);
  const [oppen, setOppen] = useState(false);

  const oppenToggle = () => {
    setOppen(!oppen);
  };

  useEffect(() => {
    if (id != currentId) {
      setOppen(false);
    }
  }, [id, currentId]);

  return (
    <div className={`dropdown ${className}`}>
      <button
        className="dropdown_trigger"
        id={id}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={oppenToggle}
        style={{ color: oppen ? "#4D9FF8" : hover ? "#868686" : "#222222" }}
      >
        {btnText}
        <svg
          className="dropdown_img"
          width="12.000000"
          height="6.000000"
          viewBox="0 0 12 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ transform: oppen ? "rotate(180deg)" : "rotate(0)" }}
        >
          <desc>Created with Pixso.</desc>
          <defs />
          <path
            id="Icon / chevron.left"
            d="M6 6C6.23 6 6.42 5.92 6.6 5.76L11.77 1.16C11.92 1.03 12 0.87 12 0.68C12 0.3 11.66 0 11.21 0C11 0 10.79 0.07 10.64 0.2L6 4.35L1.35 0.2C1.19 0.07 1 0 0.78 0C0.33 0 0 0.3 0 0.68C0 0.87 0.07 1.03 0.22 1.16L5.39 5.76C5.57 5.92 5.76 5.99 6 6Z"
            fill={oppen ? "#4D9FF8" : hover ? "#868686" : "#222222"}
            fill-opacity="1.000000"
            fill-rule="nonzero"
          />
        </svg>
      </button>
      <div
        className="dropdown_content"
        style={{ width: contentWidth, display: oppen ? "block" : "none" }}
      >
        {children}
      </div>
    </div>
  );
};

export default DropDown;
