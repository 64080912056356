import React from "react";
import plusIcon from "assets/images/icons/plusIcon.svg";
import "./style.css";

const ActionButton = ({ title, onClick, className }) => {
  return (
    <div
      className={`blueButton actionButton_container ${className}`}
      onClick={onClick}
    >
      <img src={plusIcon} atl="plusIcon" />
      <div className="p13 actionButton_titleContainer">{title}</div>
    </div>
  );
};

export default ActionButton;
