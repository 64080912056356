// import NoAuthLayout from "components/layout/noAuth/NoAuthLayout";

export const noAuthRoutings = [
    {
        path: '/',
        // element: <NoAuthLayout />,
        navigate: '/',
        children: [
            // {
            //     path: 'login',
            //     index: true,
            //     element: <Login />
            // },
        ]
    },
]
