import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";
import {connect} from "react-redux";
import {CLOSE_MODAL, SET_VISIBLE_MODAL} from "store/actionTypes";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import styles from './styles.module.scss'
import {Select, Upload} from "antd";
import FileCard from "../../../ui/fileCard/FileCard";
import {api_details} from "../../../../api/admin/details";
import {api_statuses} from "../../../../api/admin/statuses";

function AddNewModel({setVisible, setClose, modal}) {
    const [statuses, setStatuses] = useState([]);
    const [STL, setSTL] = useState();
    const [status, setStatus] = useState();

    useEffect(() => {
        getStatusesResult().then(result => {
            setStatuses(result.data)
        });
    }, []);

    useEffect(() => {
        if (modal.data) {
            setValue('name', modal.data.name)
            setValue('zone', modal.data.zone)
            setValue('group', modal.data.group)
            setValue('nfc_code', modal.data.nfc_code)

        }
    }, [modal]);

    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors},
    } = useForm({
        defaultValues: {
            name: '',
            zone: '',
            group: '',
            nfc_code: '',
        }
    });
    const {Option} = Select;

    const getResult = async (data) => {
        if (modal.data) {
            return await api_details.patchDetailByID(data, modal.data.id);
        }
        return await api_details.addDetails(data);
    }

    const getStatusesResult = async () => {
        return await api_statuses.getStatuses({});
    }

    const request = (data) => {
        let dataForm = new FormData();
        dataForm.append('name', data.name);
        dataForm.append('group', data.group);
        dataForm.append('zone', data.zone);
        dataForm.append('nfc_code', data.nfc_code);
        dataForm.append('detail_status_id', status);
        dataForm.append('stl', STL.file);
        getResult(dataForm).then(r => setClose());
    };

    const changeSTL = (STL) => {
        setSTL(STL);
    };
    const beforeUpload = (file) => {
        return file.name.indexOf('.stl') > -1 || Upload.LIST_IGNORE;

    }

    return (
        <form
            onSubmit={handleSubmit(request)}
            className={styles.container}
        >
            <p className="p17">{modal.data ? 'Update model' : 'Add new model'}</p>
            <input
                {...register("name")}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                className={`${styles.input} ${errors.name ? styles.error : ""}`}
                placeholder="Name"
                type="text"
            />
            <input
                {...register("zone")}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                className={`${styles.input} ${errors.zone ? styles.error : ""}`}
                placeholder="Zone"
                type="text"
            />
            <input
                {...register("group")}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                className={`${styles.input} ${errors.group ? styles.error : ""}`}
                placeholder="Group"
                type="text"
            />
            <input
                {...register("nfc_code")}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                className={`${styles.input} ${errors.nfc_code ? styles.error : ""}`}
                placeholder="NFC code"
                type="text"
            />
            {!modal.data ? <Select placeholder="Choose status" onChange={(id) => {
                setStatus(id)
            }}>
                {statuses.map((item, i) => {
                    return (
                        <Option value={item.id} key={item.id}>
                            <div className={styles.option}>
                                {item.name} <span className={styles.color} style={{backgroundColor: item.color}}></span>
                            </div>
                        </Option>
                    );
                })}
            </Select> : ""}
            <Upload.Dragger
                accept={'.stl'}
                beforeUpload={beforeUpload}
                name="fileList"
                itemRender={(originNode, file, fileList, actions) => <FileCard file={file} fileList={fileList}
                                                                               removeFile={() => {
                                                                                   actions.remove();
                                                                                   changeSTL()
                                                                               }} onlyDelete/>}
                customRequest={changeSTL}
                multiple={false}
            >
                <span className="p15_600 greyText"> Drop STL file here to upload</span>
                <SimpleButton title={'Browse file'} small></SimpleButton>
            </Upload.Dragger>
            <SimpleButton disabled={!STL} title={modal.data ? "Update model" : "Add model"}/>
        </form>
    )
}

function mapStateToProps(state) {
    return state;
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: () => dispatch({type: CLOSE_MODAL}),
        setClose: () => dispatch({type: SET_VISIBLE_MODAL, modal_type: ''})
    };
}

export default connect(mapStateToProps, mapDispatvhToProps)(AddNewModel); // hint if one of the functions is not needed replace by null
  