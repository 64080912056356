export const allModelsTableWidth = [
    "40%",
    "150px",
    "150px",
    "300px",
    "20%",
    "20%",
    "300px",
]

export const allStatusesTableWidth = [
    "80%",
    "10%",
    "10%",
    "40px",
    "20px",
]