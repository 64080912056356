import AddNewModel from "components/components/modalChildrens/addNewModel/AddNewModel"
import AddNewStatus from "../../components/components/modalChildrens/addNewStatus/AddNewStatus";
import UpdateStatus from "../../components/components/modalChildrens/updateStatus/UpdateStatus";
import ReviewView from "../../components/components/modalChildrens/reviewView/ReviewView";

export const modalChildrens = {
    addNewModel: <AddNewModel />,
    addNewStatus: <AddNewStatus />,
    updateStatus: <UpdateStatus />,
    reviewView: <ReviewView />,
}