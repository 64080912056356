import axios from "axios";

const LocalToken = localStorage.getItem("studia-3d-cloud-token");
let token = LocalToken;

let headers = {
  "Content-Type": "application/json",
};
if (token) {
  headers.Authorization = `Bearer ${token}`;
}
export let instance = axios.create({
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const ApiUrl = process.env.REACT_APP_ApiUrl;

export const initToken = () => {
  const LocalToken = localStorage.getItem("studia-3d-cloud-token");
  token = LocalToken;

  instance = axios.create({
    headers: { Authorization: `Bearer ${token}` },
  });
};
