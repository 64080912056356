import {Header} from './header/Header';
import {Outlet} from "react-router-dom";
import './style.css';

const AdminLayout = () => {
    return (
        <div className="adminLayout_container">
            <Header/>
            <Outlet/>
        </div>
    )
}

export default AdminLayout;