import logo from "assets/images/images/logo.svg";
import "./style.css";
import '../../media.css';
import { useState } from "react";

export function Header() {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className="headerContainer">
      <div className="headerContainer_leftPart">
        <img src={logo} alt="logo" />
      </div>
    </div>
  );
}
