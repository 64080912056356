import React from "react";
import "./style.css";

const Characteristics = ({data}) => {
    return (
        <div className="characteristics_container">
            {data.map((item, i) => {
                return (
                    <div key={i} className="characteristics_valueContainer">
                        <p className="p13 characteristics_title">{item.title}</p>
                        {item.color ?
                            <p className="p13 characteristics_color"
                               style={{backgroundColor: item.color}}>{item.value}</p>
                            :
                            <p className="p13 characteristics_value">{item.value}</p>
                        }
                    </div>
                );
            })}
        </div>
    );
};

export default Characteristics;
