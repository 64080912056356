import { connect } from "react-redux";
import { Modal } from "antd";
import { CLOSE_MODAL } from 'store/actionTypes';
import closingButton from 'assets/images/icons/closingButton.svg'
import "./style.css";
import { useEffect, useState } from "react";

function CustomModal({ type, setVisible, modalComponent }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (type) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [type]);

  return (
    <Modal
      title=""
      visible={open}
      onCancel={setVisible}
      footer={[]}
      className="customModal"
      closable={false}
    >
      {type && modalComponent}
      <div className="customModal_closingButtonContainer" onClick={setVisible}>
        <img src={closingButton} alt='closingButton' />
      </div>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {
    visible: state.modal.visible,
    type: state.modal.type,
    modalComponent: state.modal.component,
  };
}

function mapDispatvhToProps(dispatch) {
  return {
    setVisible: () => dispatch({ type: CLOSE_MODAL}),
  };
}

export default connect(mapStateToProps, mapDispatvhToProps)(CustomModal);
