import TableCard from "components/ui/tableCard/TableCard";
import { adminStatusTable } from "data/userTableHeader";
import { allStatusesTableWidth } from "../../../data/userTableWidth";
import { connect } from "react-redux";
import { SET_VISIBLE_MODAL } from "../../../store/actionTypes";
import React, { useEffect, useState } from "react";
import { api_statuses } from "../../../api/admin/statuses";
import editIcon from "../../../assets/images/icons/editIcon.svg";
import { useSearchParams } from "react-router-dom";
import { Button, Popover } from "antd";
import deleteIcon from "../../../assets/images/icons/deleteIcon.svg";
import { div } from "three/nodes";
import { SortList } from "utils/SortList";

const StatusManagement = ({ setVisible, modal }) => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const [statuses, setStatuses] = useState([]);
  const [meta, setMeta] = useState(null);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("");

  useEffect(() => {
    setSearchParams({ ...searchParams, page });
  }, [page]);

  useEffect(() => {
    console.log(sort);

    if (!modal.type) {
      getResult().then((result) => {
        setStatuses(SortList(result.data, sort));

        setMeta(result.meta);
      });
    }
  }, [
    Object.fromEntries([...searchParams]).order_by,
    Object.fromEntries([...searchParams]).order,
    Object.fromEntries([...searchParams]).limit,
    Object.fromEntries([...searchParams]).page,
    sort,
    modal.type,
  ]);

  const getResult = async () => {
    const query = Object.fromEntries([...searchParams]);
    query.limit = query.limit ?? limit;
    query.page = query.page ?? page;
    setSearchParams(query);
    return await api_statuses.getStatuses(query);
  };

  const action = (data) => {
    setVisible(data);
  };

  const content = (el) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          type="primary"
          danger
          style={{ width: "100%" }}
          onClick={() => {
            deleteModel(el);
          }}
        >
          Delete
        </Button>
      </div>
    );
  };

  const deleteModel = async (el) => {
    await api_statuses.deleteStatuses(el.id).then(() => {
      getResult().then((result) => {
        setStatuses(result.data);
        setMeta(result.meta);
      });
    });
  };

  const createTableData = (statuses) => {
    if (!statuses.length) {
      return [];
    }

    return statuses.map((el) => [
      {
        type: "text",
        content: el.name,
      },
      {
        type: "text",
        content: el.sort,
      },
      {
        type: "color",
        content: el.color,
      },
      {
        type: "actions",
        content: editIcon,
        action: () => action(el),
      },
      {
        type: "actions-remove",
        content: (
          <Popover content={content(el)} title="Are you sure ?">
            <img className="pointer" src={deleteIcon} alt="actionButtons" />
          </Popover>
        ),
      },
    ]);
  };
  return (
    <TableCard
      title={"Status"}
      actionButtonTitle={"Add status"}
      actionButton={() => setVisible()}
      createTableData={createTableData(statuses)}
      headerData={adminStatusTable}
      width={allStatusesTableWidth}
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      meta={meta}
      page={page}
      setPage={setPage}
      setSort={setSort}
    />
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    setVisible: (data) =>
      dispatch({ type: SET_VISIBLE_MODAL, modal_type: "addNewStatus", data }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusManagement);
