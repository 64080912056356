import React, { useEffect, useRef, useState } from "react";
import linkIcon from "assets/images/icons/linkIcon.svg";
import editIcon from "assets/images/icons/editIcon.svg";
import deleteIcon from "assets/images/icons/deleteIcon.svg";
import Characteristics from "../../characteristics/Characteristics";
import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";
import { connect } from "react-redux";
import { SET_VISIBLE_MODAL } from "store/actionTypes";
import "./style.css";
import "./media.css";
import icon from "assets/images/icons/updateStatus.svg";
import noModel from "assets/images/icons/noModel.svg";
import { StlViewer } from "react-stl-viewer";
import { api_statuses_history } from "../../../../api/admin/statusesHistory";
import { div } from "three/nodes";
import { api_details } from "../../../../api/admin/details";
import { useParams } from "react-router-dom";
import { Store } from "react-notifications-component";
import { Button, Carousel, Popover } from "antd";
import FileCard from "../../fileCard/FileCard";

const style = {
  top: 0,
  left: 0,
  width: "100%",
  height: "400px",
};

const contentStyle = {
  margin: 0,
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
};

const ModelWindow = ({
  setVisible,
  setVisibleUpdateModel,
  img,
  data,
  modal,
  setData,
  setModelRemoved,
  setVisibleReviewView,
}) => {
  const [activeTab, setActiveTab] = useState(1);
  const [statusesHistory, setStatusesHistory] = useState();
  const [allHistory, setAllHistory] = useState([]);
  const [listStatus, setListStatus] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [state, setState] = useState({});
  const slider = useRef(null);
  const { id } = useParams();
  let isUser = false;
  if (!state.id) {
    state.name = "";
    state.nfc_code = "";
    state.zone = "";
    state.group = "";
    state.updated_at = "";
    state.detail_status = { name: "", color: "" };
  }

  if (window.location.pathname.indexOf("user") > -1) {
    isUser = true;
  }

  const showNotification = () => {
    Store.addNotification({
      title: "Copy",
      message: "You copied the link for NFC",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 1000,
        onScreen: true,
      },
    });
  };

  useEffect(() => {
    if (!data?.id) {
      api_details.getDetailByID(id).then((result) => {
        setState(result.data);
      });
    } else {
      setState(data);
    }
  }, [data]);

  useEffect(() => {
    if (!modal.type && state.id) {
      getStatusesHistoryResult()
        .then((result) => {
          setStatusesHistory(result[0]);
          setAllHistory(result);
        })
        .catch(() => {
          setStatusesHistory({});
        });
    }
  }, [state?.id, modal]);

  useEffect(() => {
    const result = createCharacteristics(statusesHistory);
    setListStatus(result);
  }, [statusesHistory]);

  const getStatusesHistoryResult = async () => {
    return await api_statuses_history.getStatus(state.id);
  };

  const deleteModel = async () => {
    await api_details.deleteDetailByID(data.id).then(() => {
      setData([]);
      setModelRemoved(true);
    });
  };

  const getData = () => {
    const dateObject = new Date(state.updated_at);
    let newData = {
      ID: state.id,
      Name: state.name,
      NFC: state.nfc_code,
      Zone: state.zone,
      Group: state.group,
      ["Last Update"]: `${dateObject.getFullYear()}-${(
        dateObject.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${dateObject
        .getDate()
        .toString()
        .padStart(2, "0")} ${dateObject
        .getHours()
        .toString()
        .padStart(2, "0")}:${dateObject
        .getMinutes()
        .toString()
        .padStart(2, "0")}`,
      Status: state.detail_status,
    };
    return Object.keys(newData).map((key) => {
      if (key === "Status") {
        return {
          title: key,
          value: newData[key].name,
          color: newData[key].color,
        };
      }
      return {
        title: key,
        value: newData[key],
      };
    });
  };

  const content = (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Button
        type="primary"
        danger
        style={{ width: "100%" }}
        onClick={() => {
          deleteModel();
        }}
      >
        Delete
      </Button>
    </div>
  );

  const createCharacteristics = (data) => {
    const dateObject = new Date(data?.updated_at);
    return data?.updated_at
      ? [
          {
            title: "Date",
            value: `${dateObject.getFullYear()}-${(dateObject.getMonth() + 1)
              .toString()
              .padStart(2, "0")}-${dateObject
              .getDate()
              .toString()
              .padStart(2, "0")} ${dateObject
              .getHours()
              .toString()
              .padStart(2, "0")}:${dateObject
              .getMinutes()
              .toString()
              .padStart(2, "0")}`,
          },
          {
            title: "Old status",
            value: data?.previous_status?.name,
            color: data?.previous_status?.color,
          },
          {
            title: "New Status",
            value: data?.new_status?.name,
            color: data?.new_status?.color,
          },
        ]
      : [];
  };

  if (state.id) {
    return (
      <div className={`modelWindow_container`}>
        <div className="p17">
          {state.name}
          {!isUser && (
            <div className="modelWindow_actionButtons">
              <img
                className="pointer"
                src={linkIcon}
                onClick={() => {
                  navigator.clipboard.writeText(
                    `https://${window.location.hostname}/user/model/${data.id}`
                  );
                  showNotification();
                }}
                alt="actionButtons"
              />
              <img
                className="pointer"
                onClick={() => setVisibleUpdateModel(data)}
                src={editIcon}
                alt="actionButtons"
              />
              <Popover content={content} title="Are you sure ?" trigger="click">
                <img className="pointer" src={deleteIcon} alt="actionButtons" />
              </Popover>
            </div>
          )}
        </div>
        <Characteristics data={getData()} />
        <SimpleButton
          title={"Update status"}
          img={icon}
          onClick={() => setVisible(state.id)}
        />
        <div className="Tabs">
          <ul className="nav">
            <li
              className={`nav-item ${activeTab === 1 ? "active" : ""}`}
              onClick={() => setActiveTab(1)}
            >
              History
            </li>
            <li
              className={`nav-item ${activeTab === 2 ? "active" : ""}`}
              onClick={() => setActiveTab(2)}
            >
              3D-model
            </li>
          </ul>
          <div className="outlet outlet-right">
            <div
              className="nav-content"
              style={{ display: activeTab === 1 ? "flex" : "none" }}
            >
              {allHistory?.length >= 1 ? (
                <div height="511px" style={{ position: "relative" }}>
                  <div className="arrow_wrapper">
                    {currentSlide > 0 ? (
                      <button
                        className="arrow left"
                        onClick={() => slider.current.prev()}
                      >
                        <img
                          style={{ width: 35, height: 35 }}
                          src="https://img.icons8.com/?size=100&id=60636&format=png&color=000000"
                          alt=""
                        />
                      </button>
                    ) : null}

                    {currentSlide + 1 != allHistory?.length ? (
                      <button
                        className="arrow right"
                        onClick={() => slider.current.next()}
                      >
                        <img
                          style={{
                            width: 35,
                            height: 35,
                            transform: "rotate(180deg)",
                          }}
                          src="https://img.icons8.com/?size=100&id=60636&format=png&color=000000"
                          alt=""
                        />
                      </button>
                    ) : null}
                  </div>
                  <Carousel
                    arrows
                    ref={slider}
                    afterChange={(current) => setCurrentSlide(current)}
                    dotPosition="bottom"
                    infinite={false}
                    nextArrow={<span style={{ fontSize: 30 }}>next</span>}
                    dots={{ className: "dots" }}
                    draggable
                  >
                    {allHistory?.map((el, i) => (
                      <div key={i} onClick={() => setVisibleReviewView(el)}>
                        {el.images.length && el.images[0]?.path ? (
                          <img
                            style={contentStyle}
                            className="modelWindow_img"
                            src={el.images[0].path}
                            alt="model"
                          />
                        ) : (
                          ""
                        )}
                        {!el.images?.length || !el.images[0]?.path ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <img
                              className="modelWindow_img modelWindow_img-noHistory"
                              src={noModel}
                              alt="model"
                            />
                            <p className="noHistory">No photo</p>
                          </div>
                        ) : (
                          ""
                        )}
                        <textarea
                          disabled={true}
                          className="textarea"
                          placeholder="Comment (optional)"
                          value={el.comment}
                        ></textarea>
                        <Characteristics data={createCharacteristics(el)} />
                      </div>
                    ))}
                  </Carousel>
                </div>
              ) : (
                <div>
                  {statusesHistory?.image ? (
                    <div>
                      <img
                        style={contentStyle}
                        className="modelWindow_img"
                        src={statusesHistory?.image}
                        alt="model"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {!statusesHistory?.image && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <img
                        className="modelWindow_img modelWindow_img-noHistory"
                        src={noModel}
                        alt="model"
                      />
                      <p className="noHistory">No History</p>
                    </div>
                  )}
                  {statusesHistory?.comment && (
                    <textarea
                      disabled={true}
                      className="textarea"
                      placeholder="Comment (optional)"
                      value={statusesHistory.comment}
                    ></textarea>
                  )}
                  {listStatus.length ? (
                    <Characteristics data={listStatus} />
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
            <div
              className="nav-content"
              style={{ display: activeTab === 2 ? "flex" : "none" }}
            >
              <StlViewer style={style} orbitControls shadows url={state.stl} />
              <FileCard file={state.stl} onlyDownload />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div>Looking for a model</div>;
  }
};

function mapStateToProps(state) {
  return state;
}

function mapDispatvhToProps(dispatch) {
  return {
    setVisible: (id) =>
      dispatch({
        type: SET_VISIBLE_MODAL,
        modal_type: "updateStatus",
        data: id,
      }),
    setVisibleUpdateModel: (data) =>
      dispatch({
        type: SET_VISIBLE_MODAL,
        modal_type: "addNewModel",
        data: data,
      }),
    setVisibleReviewView: (data) =>
      dispatch({
        type: SET_VISIBLE_MODAL,
        modal_type: "reviewView",
        data: data,
      }),
  };
}

export default connect(mapStateToProps, mapDispatvhToProps)(ModelWindow);
