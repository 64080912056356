import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";
import {connect} from "react-redux";
import {CLOSE_MODAL, SET_VISIBLE_MODAL} from "store/actionTypes";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import styles from './styles.module.scss';
import {CirclePicker} from "react-color";
import {api_statuses} from "../../../../api/admin/statuses";

function AddNewStatus({setVisible, setClose, modal}) {
    const [color, setColor] = useState();
    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors},
    } = useForm({
        defaultValues: {
            name: '',
            sort: '',
        }
    });
    useEffect(() => {
        if (modal.data) {
            setColor(modal.data.color);
            setValue('name', modal.data.name)
            setValue('sort', modal.data.sort)

        }
    }, [modal]);

    const getResult = async (data) => {
        if (modal.data) {
            return await api_statuses.updateStatuses(data, modal.data.id);
        }
        return await api_statuses.addStatuses(data);
    }

    const handleChangeComplete = (color) => {
        setColor(color.hex);
    };


    const request = (data) => {
        data.color = color;
        data.sort = +data.sort;
        getResult(data).then(r => setClose());
    };
    return (
        <form
            onSubmit={handleSubmit(request)}
            className={styles.container}
        >
            <p className="p17">Add new status</p>
            <input
                {...register("name")}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                className={`${styles.input} ${errors.name ? styles.error : ""}`}
                placeholder="Name"
                type="text"
            />
            <input
                {...register("sort")}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                className={`${styles.input} ${errors.sort ? styles.error : ""}`}
                placeholder="Priority"
                type="text"
            />
            <div className={styles.color}>
                Color
                <CirclePicker
                    color={color}
                    onChangeComplete={handleChangeComplete}/>
            </div>
            <SimpleButton title={"Add status"}/>
        </form>
    )
}

function mapStateToProps(state) {
    return state;
}

function mapDispatvhToProps(dispatch) {
    return {
        setVisible: () => dispatch({type: CLOSE_MODAL}),
        setClose: () => dispatch({type: SET_VISIBLE_MODAL, modal_type: ''})
    };
}

export default connect(mapStateToProps, mapDispatvhToProps)(AddNewStatus);
  