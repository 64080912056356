import { Suspense, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";

import ModelCard from "components/ui/modelCard/ModelCard";
import TableCard from "components/ui/tableCard/TableCard";

import { allModelsTableWidth } from "../../../data/userTableWidth";
import { SET_VISIBLE_MODAL } from "../../../store/actionTypes";
import { api_details } from "../../../api/admin/details";
import { adminModelTable } from "data/userTableHeader";
import { ConvertDate } from "utils/ConvertDate";

import styles from "./styles.module.scss";
import modelIcon from "assets/images/icons/modelIcon.svg";

const ModelManagement = ({ setVisible, modal }) => {
  const [models, setModels] = useState([]);
  const [activeRow, setActiveRow] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams({});
  const [meta, setMeta] = useState(null);
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [orderType, setOrderType] = useState("");
  const [order, setOrder] = useState("");
  const [modelRemoved, setModelRemoved] = useState(false);
  const [modelUpdate, setModelUpdate] = useState(false);

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      page,
      search,
      order_type: orderType,
      order,
    });
  }, [page, search, orderType, order]);
  const getResult = async () => {
    const query = { ...Object.fromEntries([...searchParams]) };
    let params = {
      limit: query.limit ?? limit,
      page: query.page ?? page,
      search: query.search ?? "",
    };
    if (query.order_type && query.order_type !== "null") {
      params.order_type = query.order_type ?? "";
    }
    if (params.order_type) {
      params.order = query.order;
    }
    return await api_details.getDetails(params);
  };

  useEffect(() => {
    if (modelRemoved) {
      getResult().then((result) => {
        setModels(result.data);
        setMeta(result.meta);
        setModelRemoved(false);
      });
    }
  }, [modelRemoved]);

  useEffect(() => {
    if (!modal.type) {
      getResult().then((result) => {
        setModels(result.data);
        setMeta(result.meta);
        if (activeRow?.id) {
          let updatedElement = result.data.find((el) => el.id === activeRow.id);
          setActiveRow(updatedElement);
        }
      });
    }
  }, [
    Object.fromEntries([...searchParams]).search,
    Object.fromEntries([...searchParams]).order_type,
    Object.fromEntries([...searchParams]).order,
    Object.fromEntries([...searchParams]).limit,
    Object.fromEntries([...searchParams]).page,
    modal.type,
  ]);

  const fetchItems = (table) => {
    let row = table.find((row) => row.type === "id");
    let model = models.find((model) => model.id === row.content);
    setActiveRow(model);
  };

  const createTableData = (models) => {
    if (!models.length) {
      return [];
    }

    return models.map((el) => [
      {
        type: "text",
        content: el.name,
      },
      {
        type: "text",
        content: el.zone,
      },
      {
        type: "text",
        content: el.group,
      },
      {
        type: "status",
        content: el.detail_status?.name,
        color: el.detail_status?.color,
      },
      {
        type: "text",
        content: ConvertDate(el.created_at),
      },
      {
        type: "id",
        content: el.id,
      },
      {
        type: "text",
        content: el.nfc_code,
      },
    ]);
  };
  return (
    <div className={styles.container}>
      <TableCard
        title={"Models"}
        actionButtonTitle={"Add model"}
        actionButton={setVisible}
        createTableData={createTableData(models)}
        headerData={adminModelTable}
        width={allModelsTableWidth}
        search={true}
        fetchItems={fetchItems}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        meta={meta}
        page={page}
        setPage={setPage}
        searchData={search}
        setSearch={setSearch}
        setOrderType={setOrderType}
        setOrder={setOrder}
        orderType={orderType}
        order={order}
        activeRow={activeRow}
        setModels={setModels}
      />
      <ModelCard
        data={activeRow}
        setData={setActiveRow}
        emptyTitle={`select 3D-model`}
        emptyImg={modelIcon}
        setModelRemoved={setModelRemoved}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    setVisible: () =>
      dispatch({ type: SET_VISIBLE_MODAL, modal_type: "addNewModel" }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelManagement);
