import React from "react";
import { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import menu from "assets/images/icons/menu.svg";
import closingButton from "assets/images/icons/closingButton.svg";
import logo from "assets/images/images/logo.svg";
import activeMenu from "assets/images/icons/activeMenu.svg";
import nonActiveMenu from "assets/images/icons/nonActiveMenu.svg";
import "./style.css";
import "./media.css";
import { useEffect } from "react";

const DrawerMenu = ({ menuList, setShowMenu, showMenu }) => {
  const [activeUrl, setActiveUrl] = useState(``);
  const location = useLocation();

  useEffect(() => {
    setActiveUrl(window.location.href);
  }, [location]);

  return (
    <>
      <div className={`drawerMenu ${showMenu ? "active" : ""}`}>
        <div className={`drawerMenu_imgMenu ${showMenu ? "active" : ""}`}>
          <img
            src={logo}
            alt="logo"
            className={`drawerMenu_logoImg ${showMenu ? "active" : ""}`}
          />

          <div className="drawerMenu_menuButton">
            <img
              src={closingButton}
              alt="menu"
              onClick={() => setShowMenu(!showMenu)}
              className="drawerMenu_closingButton"
            />
          </div>
        </div>

        <div className={`drawerMenu_menuList ${showMenu ? "active" : ""}`}>
          {menuList.map((item, i) => {
            return (
              <NavLink
                to={item.path}
                key={`menuList-${i}`}
                className={({ isActive }) =>
                  isActive ? "drawerMenu_link active-link" : "drawerMenu_link"
                }
              >
                <div className="drawerMenu_menuListContainer">
                  <div
                    className={
                      activeUrl.includes(item.path)
                        ? "drawerMenu_borderLeft"
                        : ""
                    }
                  ></div>
                  <span className={`drawerMenu_imgContainer ${activeUrl.includes(item.path) ? 'active-img':''}`}>{item.img}</span>
                  <span className="drawerMenu_titleContainer">
                    {item.title}
                  </span>
                </div>
              </NavLink>
            );
          })}
        </div>
      </div>
      {showMenu && (
        <div
          className={`drawerMenuBg`}
          onClick={() => setShowMenu(!showMenu)}
        ></div>
      )}
    </>
  );
};

export default DrawerMenu;
