import React from "react";
import { Header } from "./header/Header.jsx";
import "./style.css";
import { Outlet } from "react-router-dom";

const UserLayout = () => {

  return (
    
    <div className="userLayoutContainer">
      <Header />
        <Outlet />
    </div>
  );
};

export default UserLayout;
