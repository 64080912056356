import AdminLayout from "components/layout/admin/AdminLayout";
import ModelManagement from "views/admin/modelManagement/ModelManagement";
import StatusManagement from "../../views/admin/statusManagement/StatusManagement";
import {Navigate} from "react-router-dom";


export const adminRoutings = [
    {
        path: '/admin',
        element: <AdminLayout />,
        navigate: '/admin/my-models',
        children: [
            {
                path: '',
                element: <Navigate to="/admin/my-models" />
            },
            {
                path: 'my-models',
                index: false,
                element: <ModelManagement />
            },
            {
                path: 'statuses',
                index: false,
                element: <StatusManagement />
            },
        ]
    }
]