import ActionButton from "components/shared/buttons/actionButton/ActionButton";
import React, { useEffect, useState } from "react";
import Table from "../table/Table";
import style from "./style.module.scss";
import "./media.css";
import { Input, Pagination } from "antd";
import DropDown from "../dropDown/DropDown";
import { api_statuses } from "api/admin/statuses";
import { api_details } from "api/admin/details";
import useDebounce from "hooks/useDebounce";

const TableCard = ({
  title,
  fetchItems,
  actionButtonTitle,
  actionButton,
  createTableData,
  headerData,
  activeRow,
  width,
  search,
  searchParams,
  setSearchParams,
  meta,
  page,
  setPage,
  setSearch,
  searchData,
  setOrderType,
  setOrder,
  orderType,
  order,
  setModels,
  setSort,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const { Search } = Input;
  const [searchString, setSearchString] = useState(searchData);
  const [statuses, setStatuses] = useState([]);
  const [oppenFilter, setOpenFilter] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [metaData, setMetaData] = useState(null);

  const [filters, setFilters] = useState({
    group: "",
    zone: "",
    status: "",
    search: "",
    sort: "",
    orderType: "",
  });

  const [pickedStatuses, setPickedStatuses] = useState([]);

  const defferedGroup = useDebounce(filters.group, 500);
  const defferedZone = useDebounce(filters.zone, 500);
  const defferedSearch = useDebounce(filters.search, 500);

  useEffect(() => {
    getStatusesList();
  }, []);

  function onSearch(value) {
    setSearch(value);
  }

  useEffect(() => {
    const closeFilters = (e) => {
      if (e.key === "Escape") {
        setOpenFilter(-1);
      }
    };

    window.addEventListener("keydown", closeFilters);

    return () => window.removeEventListener("keydown", closeFilters);
  }, []);

  const getStatusesList = () => {
    api_statuses
      .getStatuses("")
      .then((res) => setStatuses([...res.data]))
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    console.log(pickedStatuses);
  }, [pickedStatuses]);

  const updateModels = (page) => {
    api_details
      .searchDetails({
        group: filters.group.toUpperCase(),
        zone: filters.zone,
        search: filters.search,
        sort: filters.sort,
        status: pickedStatuses.toString().replace(",", ", "),
        order_type: filters.orderType,
        page: page || currentPage,
      })
      .then((res) => {
        setModels([...res.data]);
        setMetaData(res.meta);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    updateModels(1);

    if (title === "Status") {
      setSort(filters.orderType);
    }
  }, [
    defferedGroup,
    defferedZone,
    defferedSearch,
    pickedStatuses,
    filters.sort,
    filters.orderType,
  ]);

  useEffect(() => {
    updateModels(currentPage);
  }, [currentPage]);

  const setCurrentFilterId = (e) => {
    const target = e.target;
    const tag = target.tagName;
    const parent = target.parentNode;

    if (tag === "BUTTON") {
      setOpenFilter(target.id);
    } else if (tag === "svg") {
      setOpenFilter(parent.id);
    } else if (tag === "path") {
      setOpenFilter(parent.parentNode.id);
    }
  };

  const onPickStatus = (id) => {
    if (pickedStatuses.length > 0) {
      pickedStatuses.forEach((el) => {
        if (!pickedStatuses.find((el) => el === id)) {
          setPickedStatuses([...pickedStatuses, id]);
        } else if (el == id) {
          setPickedStatuses(pickedStatuses.filter((el) => el != id));
        }
      });
    } else {
      setPickedStatuses([...pickedStatuses, id]);
    }

    document.getElementById(`${id}`).classList.toggle("item_active");
  };

  const resetStatusFilters = () => {
    setPickedStatuses([]);

    pickedStatuses.forEach((el) => {
      document.getElementById(el).classList.remove("item_active");
    });
  };

  return (
    <div className={style.tableCard_container}>
      <div className={style.topHolder}>
        <div className="p24">{title}</div>

        {search ? (
          <>
            <div
              className={`${style.filters}`}
              onClick={(e) => setCurrentFilterId(e)}
            >
              <DropDown
                className={`${filters.zone ? style.dropdown : ""}`}
                contentWidth={"170px"}
                btnText={"By zone"}
                id={"drop_0"}
                currentId={oppenFilter}
              >
                <div className={`${style.dropdown_inner}`}>
                  <p className={`${style.dropdown_text}`}>
                    Enter the zone numbers separated by commas
                  </p>
                  <input
                    className={`${style.dropdown_input}`}
                    type="text"
                    placeholder="№"
                    onChange={(e) =>
                      setFilters({ ...filters, zone: e.target.value })
                    }
                  />
                </div>
              </DropDown>
              <DropDown
                className={`${filters.group ? style.dropdown : ""}`}
                contentWidth={"170px"}
                btnText={"By group"}
                id={"drop_1"}
                currentId={oppenFilter}
              >
                <div className={`${style.dropdown_inner}`}>
                  <p className={`${style.dropdown_text}`}>
                    Enter the group numbers separated by commas
                  </p>
                  <input
                    className={`${style.dropdown_input}`}
                    type="text"
                    placeholder="№"
                    onChange={(e) =>
                      setFilters({ ...filters, group: e.target.value })
                    }
                  />
                </div>
              </DropDown>
              <DropDown
                className={`${pickedStatuses.length > 0 ? style.dropdown : ""}`}
                contentWidth={"130px"}
                btnText={"By status"}
                id={"drop_2"}
                currentId={oppenFilter}
              >
                <ul className={`${style.statuses_list}`}>
                  <li className={`${style.statuses_list_item}`}>
                    <button
                      className={`${style.statuses_btn}`}
                      onClick={resetStatusFilters}
                    >
                      All
                    </button>
                  </li>
                  {statuses.map((status) => (
                    <li
                      className={`${style.statuses_list_item}`}
                      id={status.id}
                      key={status.id}
                    >
                      <button
                        className={`${style.statuses_btn}`}
                        onClick={() => onPickStatus(status.id)}
                      >
                        {status.name}
                      </button>
                    </li>
                  ))}
                </ul>
              </DropDown>
            </div>
            <Search
              placeholder={"Find by  Name, ID, NFC"}
              onSearch={onSearch}
              onChange={(e) =>
                setFilters({ ...filters, search: e.target.value })
              }
            />
          </>
        ) : (
          ""
        )}
        <ActionButton
          className={style.action}
          title={actionButtonTitle}
          onClick={actionButton}
        />
      </div>
      <Table
        header={headerData}
        width={width}
        data={createTableData}
        fetchItems={fetchItems}
        searchTerm={searchTerm}
        activeRow={activeRow}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        setOrderType={setOrderType}
        setOrder={setOrder}
        orderType={orderType}
        order={order}
        filters={filters}
        setFilters={setFilters}
      />
      <Pagination
        align="start"
        defaultCurrent={1}
        total={metaData?.total}
        pageSize={50}
        defaultPageSize={50}
        showSizeChanger={false}
        showQuickJumper={false}
        onChange={(number) => {
          setCurrentPage(number);
        }}
      />
    </div>
  );
};
export default TableCard;
